import soundON from '../../static/images/icon/sound-max.svg'
import soundMute from '../../static/images/icon/sound-mute.svg'
import { servObj } from './app/init_glob.js';

export function getRootHref(href=window.location.href) {
    let loc = window.location;
    let indx = href.indexOf('index');
    let root = href.includes('testing') ? href.slice(0, href.indexOf('testing')) : 
                    href.includes('thing') ? href.slice(0, href.indexOf('thing')):
                    href.slice(0, indx == -1 ? href.length : indx);
    return root.replace(loc.search, '').replace(loc.hash, '');
}

let rootHref = getRootHref();

(async function() {

    if(!window.servObj){
        window.servObj = await import("./app/init_glob.js");
    }

    let searchParams = new URLSearchParams(window.location.search);
    servObj.activeLocation = searchParams.get('loc');
    if(servObj.activeLocation && servObj.activeLocation !== 'village') servObj.gameIsStarted =  true;

    const container = document.querySelector('.container')
    let href = window.location.href;
    
    // <a class="logo-link" href="${href.includes('/testing') ? '../index.html':'./index.html'}"></a>

    const header = `
        <header class="header">
            <div class="logo">
                ХОЛЬМГАРД
            </div>
            <ul class="menu-list">
                <li class="menu-item modal-links" data-modal-name="about-project">О проекте</li>
                <li class="menu-item testing">Тестирование</li>
                <li class="menu-item modal-links" data-modal-name="project-partners">Партнеры</li>
                <li class="menu-item modal-links" data-modal-name="news">Новости</li>
            </ul>
        </header>
    `
    
    const indexFooter = `
        <div class="footer">
            <div class="controls">
                <div class="sound-icon-wrap">
                    <div class="sound-icon"></div>
                </div>
                <div class="help-icon-wrap modal-links" data-modal-name="help-modal">
                    <div class="help-icon"></div>
                </div>
            </div>
            <div class="back-to-village-wrap">
                <button class="back-to-village">Вернуться в Городище</button>
            </div>
            <div class="current-testing-wrap">
                <button>Пройти тест мастерской</button>
            </div>
        </div>
    `
    
    const testingFooter = `
        <div class="footer">
            <div class="controls">
                <div class="sound-icon-wrap">
                    <div class="sound-icon"></div>
                </div>
                <div class="help-icon-wrap modal-links" data-modal-name="help-modal">
                    <div class="help-icon"></div>
                </div>
            </div>
            <div class="backto-btns-wrap">
                <div class="back-to-choosing-test">
                    <button>Вернуться к списку тем</button>
                </div>
                <div class="back-to-workshop-wrap">
                    <button>Назад в мастерскую</button>
                </div>
                <div class="back-to-village-wrap-testing">
                    <button class="back-to-village">Вернуться в Городище</button>
                </div>
            </div>
        </div>
    `
    
    const thingsFooter = `
        <div class="footer">
            <div class="controls">
                <div class="sound-icon-wrap">
                    <div class="sound-icon"></div>
                </div>
                <div class="help-icon-wrap modal-links" data-modal-name="help-modal">
                    <div class="help-icon"></div>
                </div>
            </div>
            <div class="back-to-workshop-wrap">
                <a class="back-to-workshop-link" href="./index.html">
                    <button>Назад в мастерскую</button>
                </a>
            </div>
        </div>
    `
    
    function setMaskHTML() {
    
        if(!href || !container) return;
    
        container.insertAdjacentHTML('afterbegin', header);
    
        if(href.includes('/things/')){
            container.insertAdjacentHTML('beforeend', thingsFooter)
        }else if(href.includes('/testing/')){
            container.insertAdjacentHTML('beforeend', testingFooter); 
        }
        else{
            container.insertAdjacentHTML('beforeend', indexFooter)
        }
    
    };
    
    setMaskHTML();

    let bacToVillageBtn = document.querySelector('.back-to-village-wrap');
    let bacToVillageTestingBtn = document.querySelector('.back-to-village-wrap-testing');
    let bacToWorkshopBtn = document.querySelector('.back-to-workshop-wrap');
    let passWorkroomTestingBtn = document.querySelector('.current-testing-wrap');
    let soundIcon = document.querySelector('.sound-icon');
    let soundIconWrap = document.querySelector('.sound-icon-wrap');
    let currentTestingLink = document.querySelector('.current-testing-link');
    let testing = document.querySelector('.testing');
    let logo = document.querySelector('.logo');
    let testState = JSON.parse(window.localStorage.getItem('testState'));

    function backToVillage() {
        let pathname = window.location.pathname;
        if(pathname.includes('testing')){
            window.location.href = rootHref + '?needSetCoord=true#village';
        }else if(pathname.includes('things')){
            window.location.href = rootHref + '#village';
        }

        if(!servObj.gameIsStarted || servObj.activeLocation == 'village') return;
        statesManager.setState('reachedLocation', 'village');

    }

    logo && logo.addEventListener('pointerdown', backToVillage);

    if(bacToVillageBtn){
        bacToVillageBtn.addEventListener('pointerdown', () => {
            statesManager.setState('reachedLocation', 'village');
        });

        (bacToVillageBtn.style.display = servObj.activeLocation == 'village' && servObj.gameIsStarted ? 'block' : 'none')
    };

    bacToVillageTestingBtn && bacToVillageTestingBtn.addEventListener('pointerdown', backToVillage);


    if(passWorkroomTestingBtn){
        passWorkroomTestingBtn.style.display = servObj.activeLocation !== 'village' ? 'block' : 'none';
        passWorkroomTestingBtn.addEventListener('pointerup', function(e) {
            const location = servObj.activeLocation;
            console.log('location: ', location);
            
            if(location) {
                window.location.href = `./testing/testing.html?loc=${location}`;
                console.log('testState: ', testState);
                
                // testState && Object.values(testState).forEach(test => {
                //     if(test.testID === testID[location]) {
                //         test.isDone ? openModal('repeat-test-warning') : goToCurrentTest()
                //     }
                // })
                // !testState && goToCurrentTest()
            }
        });
    };

    if(bacToWorkshopBtn){
        bacToWorkshopBtn.style.display = servObj.activeLocation && servObj.activeLocation !== 'village' ? 'block' : 'none';
        bacToWorkshopBtn.addEventListener('pointerdown', (e)=>{
            location.href = rootHref + `#${servObj.activeLocation}`
        })
    }

    passWorkroomTestingBtn && (passWorkroomTestingBtn.style.display = 'none');

    soundIconWrap && soundIconWrap.addEventListener('pointerdown', () => {
        servObj.soundOn = !servObj.soundOn
        soundIcon.style.backgroundImage = servObj.soundOn ? `url("${soundON}")` : `url("${soundMute}")`;
    });

    soundIcon && (soundIcon.style.backgroundImage = servObj.soundOn ? `url("${soundON}")` : `url("${soundMute}")`);

    testing.addEventListener('pointerup', function(e) {
        // servObj.testIsActive = true;
        servObj.gameIsStarted = true;
    });
})()

